@font-face {
    font-family: "mont";
    src: url("../../assets/Montserrat-VariableFont_wght.ttf");
}
html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
    /*font-family: 'Teko', sans-serif;*/
}

body {
    overflow-x: hidden;
    font-family: 'Mont', sans-serif;


}

span,
a,
input,
button,
li {}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

p {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
}

a,
input,
select,
textarea {
    margin: 0;
    outline: medium none;
    padding: 0;
}

html {}

img {
    width: 100%;
}

img,
fieldset {
    border: 0 none;
}

a {
    border: medium none;
    outline: medium none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
    margin: 0;
    padding: 0;
}

a:hover {
    text-decoration: none;
}

.clear {
    clear: both;
}


img {
    image-rendering: -webkit-optimize-contrast;
}

.header_section .container {
    width: 100%;
    max-width: 1550px;
}

.container {
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
}

/* Header css */

header.header_section {
    position: absolute;
    display: block;
    width: 100%;
    float: left;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    background-color: #0A2540;
}

.mid_menu_header {
    position: relative;
    padding: 15px 0;
}

.header_logo img {
    width: 200px;
    height: auto;
}

.menus {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top-brs .top-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 2px solid #f9f9f9;
    padding-bottom: 15px;
}

.header_navigation {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-start;
    padding: 0;
}

.last-mains ul {
    display: flex;
}

.sidenavs {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
}

.sidenavs .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none;
}

.menu_navigation,
.menu-btns {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.sidenavs a {
    font-size: 16px;
    line-height: 14px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 30px;
    padding: 8px 0;
}

li.lst-btn a:last-child {
    margin-right: 0;
}

ul.menu_navigation li a {
    border-bottom: 3px solid transparent;
    text-decoration: none;
}

ul.menu_navigation li a:hover {
    border-bottom: 3px solid #3EB489;
    color:#3EB489;
}

li.lst-btn a {
    border: 1px solid #3EB489 !important;
    background: #3EB489;
    padding: 10px 20px;
    color: #fff;
    border-radius: 26px;
    box-shadow: 1px 0px 9px #00000047;
}

li.lst-btn a:hover {
    background: transparent;
    color: #3EB489;
}

span.toggle_menu {
    display: none;
}

.top-menu img {
    width: 20px;
    margin-right: 10px;
}

ul.top-menu a {
    font-size: 16px;
    line-height: 14px;
    color: #000000;
    font-weight: 400;
    margin-right: 40px;
}

li.become-member .btn {
    border-radius: 8px;
    background-color: #ff390a;
    border: 2px solid #ff390a;
    color: #fff;
    font-weight: 400;
    padding: 8px 5px;
    margin-right: 10px;
}

li.become-member .btn:hover,
.get-started-btn a:hover {
    background-color: #fff;
    border: 2px solid #ff390a;
    color: #ff390a;
}

li.free-trial .btn:hover {
    background-color: #ff390a;
    color: #fff;
}

li.free-trial .btn {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #ff390a;
    color: #ff390a;
    font-weight: 400;
    padding: 8px 30px;
    margin-right: 0;
}

.for-mob {
    display: none;
}

section.demosec {
    height: 80vh;
}

/* banner section css */
.banner_img img {
    width: 100%;
}

.banner_uppr_txt {
    text-align: center;
    position: relative;
    z-index: 999;

}

#banner_sec {
    background: url("../../topResort/TopResortsHotel-2.webp") no-repeat;
    background-size: cover;
    padding: 190px 0 439px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
}

#banner_sec:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.banner_uppr_txt h1 {
    color: #fff;
    font-family: 'Mont';
    font-size: 52px;
    font-weight: 700;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}
.banner_uppr_txt h2{
    color: #fff;
    font-family: 'Mont';
    font-size: 44px;
    font-weight: 700;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.banner_uppr_txt p {
    color: #fff;
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    font-size: 18px;
    font-family: 'Mont';
    margin-right: auto;
    margin-top: 23px;
    font-weight: 400;
    line-height: 30px;
}

.book_now {
    margin-top: 50px;
}

.book_now a {
    background: #3EB489;
    color: #fff;
    padding: 14px 40px;
    font-family: 'Mont';
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #3EB489;

}

.book_now a:hover {
    background-color: transparent;
    border: 1px solid #3EB489;
    color: #3EB489;
}

/* Banner section */
/* card */
section.cardone {
    margin-top: 80px;
}

section.cardone h2 {
    color: #3EB489;
    font-family: 'Mont';
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 550px;
}

.woh {
    margin-top: 40px;
}

.main_hotel img {
    width: 100%;
    height: 398px;
    object-fit: cover;

}

.text_hotel {
    padding: 30px;

}

.text_box {
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fff;
}

.text_hotel h3 {
    font-weight: 700;
    color: #3EB489;
    font-family: 'Mont';
    font-size: 26px;
    margin: 14px 0;
}

.text_hotel p {
    color: #0A2540;
    font-size: 16px;
    font-family: 'Mont';
    margin-right: auto;
    font-weight: 400;
    line-height: 30px;
}

.more {
    margin: 40px 0 0 0;
}

.more a {
    background: #3EB489;
    color: #fff;
    padding: 14px 40px;
    font-family: 'Mont';
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #3EB489;
}

.more a:hover {
    background-color: transparent;
    color: #3EB489;
    border: 1px solid #3EB489;
}

.img_box {
    position: relative;
}

.img_box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #c6ab728f;
    transform: scale(0);
    transition: all .5s;
    width: 100%;
    height: 100%;
}

.img_box:hover::after {
    transform: scale(1);
}

.main_hotel {
    transition: all 0.3s;
}

.main_hotel:hover {
    transform: translateY(-10px);
}

.content_new p {
    color: #0A2540;
    font-size: 17px;
    font-family: 'Mont';
    margin-right: auto;
    font-weight: 400;
    line-height: 30px;
    width: 100%;
    max-width: 832px;
    margin-left: auto;
    text-align: center;
    margin-top: 80px;
}

/* footr */
.footer_logo img {
    width: 100%;
    max-width: 139px;
}

footer.footer {
    background-color: #0A2540;
    padding: 60px 0 60px 0;
    margin-top: 100px;
}

.footer_logo p {
    color: #fff;
    font-family: 'Mont';
    font-size: 18px;
    width: 100%;
    max-width: 403px;
    margin-top: 26px;
}

.Useful_link h2,
.get h2 {
    color: #fff;
    font-size: 25px;
    font-family: 'Mont';
}

.Useful_link ul li a {
    color: #fff;
    padding: 8px 0;
    display: block;
    font-family: 'Mont';
    font-size: 16px;
}

.footer_logo ul {
    margin-top: 40px;
}

.footer_logo ul a {
    font-size: 18px;
    font-family: 'Mont';
    color: #fff;
    padding: 10px 15px;
    background: #3EB489;
    border-radius: 30px;
    margin: 0 13px 0 0;
    border: 1px solid #3EB489;
}

.footer_logo ul a:hover {
    background-color: transparent;
    border: 1px solid;
    color: #3EB489;
}

.Useful_link ul li a:hover {
    color: #3EB489;
}

.location ul li a {
    color: #fff;
    padding: 10px 0;
    display: inline-block;
    line-height: 29px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Mont';
}

.location ul li a i {
    background: #3EB489;
    text-align: center;
    border-radius: 37px;
    height: 40px;
    width: 40px;
    font-size: 20px;
    padding: 9px;
    margin: 0 10px;
    border: 1px solid #3EB489;
}

.location ul li a i:hover {
    background-color: transparent;
    border: 1px solid;
    color: #3EB489;
}

footer.footer .row {
    border-bottom: 1px solid #3e3d3c;
    padding: 0 0 41px 0;
}

.location ul li a:hover {
    text-decoration: underline;

}

.policey ul {
    display: flex;
    justify-content: flex-end;
}

footer.footer .copyright .row {
    border: none;
    padding: 0;
}

.policey ul li a {
    color: #fff;
    padding: 0px 0px 0 40px;
    display: inline-block;
    line-height: 29px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Mont';
}

.policey ul li a:hover {
    color: #3EB489;
}

.copyrights p {
    line-height: 29px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Mont';
    color: #fff;
}

.copyright {
    margin-top: 30px;
}

/* luxery section start form here */
#banner {
    background: url("../../topResort/TopResortsHotel-12.webp");
    background-size: cover;
    padding: 190px 0 280px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
    height: 604px;
    object-fit: cover;
}

.col-md-12 {
    margin-top: 50px;
}

#banner:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.second_row {
    margin-top: 50px;
}

/* luxery section start end */
/* Casino_Hotels */
#Casino_Hotels {
    background: url("../../topResort/TopResortsHotel-18.webp")no-repeat;
    background-size: cover;
    padding: 190px 0 280px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
    height: 604px;
    object-fit: cover;
}

#Casino_Hotels:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

/* Casino_Hotels */
/* Budget */
#Budget_Friendly {
    background: url("../../topResort/TopResortsHotel-22.webp");
    background-size: cover;
    padding: 190px 0 280px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
    height: 604px;
    object-fit: cover;
}

#Budget_Friendly:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

/* Budget */
/* boutique  */
#boutique {
    background: url("../../topResort/TopResortsHotel-27.webp");
    background-size: cover;
    padding: 190px 0 280px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
    height: 604px;
    object-fit: cover;
}

#boutique:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

/* boutique  */
/* contact us */

.page_text p {
    line-height: 29px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Mont';
    color: #0A2540;
    width: 100%;
    max-width: 817px;
    margin: 0 auto;
    text-align: center;
}

.content_page_text {
    margin-top: 50px;
}

.form_div input {
    width: 100%;
    padding: 16px;
    margin-bottom: 28px;
    margin-right: 14px;
    border:1px solid #eee;
}
textarea#w3review{
    border:1px solid #eee;
}

textarea#w3review {
    width: 100%;
    padding: 16px;
    margin-right: 14px;

}

.form_div {
    display: flex;
}

input#sub {
    padding: 14px 50px;
    background-color: #3EB489;
    color: #fff;
    border: none;
    margin-top: 40px;
    font-family: 'Mont';
    font-size: 16px;
}

.contact_us_f h2 {
    font-size: 37px;
    line-height: 29px;
    font-weight: 700;
    font-family: 'Mont';
    margin-bottom: 50px;
    color: #3EB489;

}

.contact_us_f {
    margin-top: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fff;
    padding: 50px;
}

.contact_us .row {
    align-items: center;
}

.touch {
    text-align: center;
}

.touch h4 {
    font-size: 43px;
    font-weight: 700;
    font-family: 'Mont';
    color: #333333;
}

.mail ul li a i {
    background: #3EB489;
    text-align: center;
    border-radius: 37px;
    height: 40px;
    width: 40px;
    font-size: 20px;
    padding: 9px;
    margin: 0 10px;
    color: #fff;
}

.mail ul li a {
    color: #333333;
}

#Contact {
    background: url(../../topResort/TopResortsHotel-31.webp);
    background-size: cover;
    padding: 190px 0 280px;
    margin: 108px 0 0 0;
    background-position: center;
    position: relative;
    height: 604px;
    object-fit: cover;
}

#Contact:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

input#sub:hover {
    background-color: #0A2540;
}

/* terms start */
.condition_wrap ul li::before {
    position: absolute;
    content: "";
    background: #000;
    width: 10px;
    height: 10px;
    top: 8px;
    left: 0;
    border-radius: 50%;
}

section.terms {
    padding: 80px 0 0 0;
}

.condition_wrap h5 {
    font-size: 26px;
    font-family: 'Mont';
    font-weight: 100;
    padding-bottom: 20px;
}

.condition_wrap p {
    font-size: 16px;
    font-family: 'Mont';
    line-height: 28px;
    margin-bottom: 0;
}

.condition_wrap p {
    padding-bottom: 20px;
}

.condition_wrap ul li::before {
    position: absolute;
    content: "";
    background: #000;
    width: 10px;
    height: 10px;
    top: 8px;
    left: 0;
    border-radius: 50%;
}

.condition_wrap ul li {
    position: relative;
    padding-left: 20px;
    padding-bottom: 15px;
}

#terms {

    background-size: cover;
    padding: 90px 0;
    margin: 125px 0 0 0;
    background-position: center;
    position: relative;
}

#terms:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffffff;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

h1.awe {
    color: #000;
}

#Privacy {
    background-size: cover;
    padding: 90px 0px;
    margin: 125px 0 0 0;
    background-position: center;
    position: relative;
}

#Privacy:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #f9f9f9;
    opacity: 0.5;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.active {
    color: #3EB489 !important;

}

a:not([href]):not([tabindex]) {
    color: #fff;
    text-decoration: none;
}

h1.policey {
    color: #000;
}

/*  */
/* mobile-view-design */
.mobile-view-design {
    display: none;
}

.mobile-view-design {
    margin-top: 166px !important;
}

.bating-brand-image img {
    width: 100%;
}

.bating-brand-image {
    width: 50%;
    text-align: center;
}

.bating-brand-text {
    width: 50%;
    text-align: center;
}

.bating-brand-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 27px;
    padding-top: 27px;
    padding-left: 13px;
    padding-bottom: 27px;
    padding-right: 6px;
    background: #ffffff;
    height: 100%;
    border: 1px solid;
    box-shadow: #000000 8px 7px;
}

.bating-brand-text h6 {
    font-size: 22px;
    padding: 2px 2px;
    text-align: center;
    color: #000;
}

a.sign-up {
    background: #000000;
    color: #fff;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    width: 100%;
    max-width: 120px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
}

.main-rate-star {
    display: flex;
    text-align: center;
    justify-content: center;
}

p.rated-star {
    padding-left: 6px;
}

p.rating-text {
    padding-bottom: 4px !important;
    margin: 0px !important;
    margin-top: 13px !important;
    color: #000;
}

i.fa.fa-star {
    color: #fab451;
}

p.rate-now {
    margin-bottom: 6px !important;
    color: #000;
}

.bating-brand-text p {
    text-align: center;
    font-size: 17px;
    line-height: 25px;
    color: #000;
}

h1.beste {
    color: #000000;
    font-size: 30px;
    text-align: center;
    margin-bottom:20px;

}

.mobile-text-end p {
    color: #000;
    padding: 0px 30px;
    text-align: center;
    margin: 31px 0px;
}


img[alt="Playfina"] {
    height: 90px;
}


img[alt="rolling-slots"] {
    height: 90px;
}

.bating-brand-image img {
    width: 100%;
    height: 90px;
    object-fit: contain;
}

@media only screen and (max-width: 575px) {
    .mob-nones {
        display: none;
    }

    .mobile-view-design {
        display: block !important;
    }

}

.todh {
    margin-top: 50px;
}

.condition_wrap p a {
    color: #b89b62;
}

.condition_wrap ul li a {
    color: #b89b62;


}
.eg-search-widget-frame {
    max-width: 900px !important;
    min-width: 300px;
}
.modal_overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .7);
    z-index: 999999;
    top:0;
    left:0;
    display: none;
}
.modal_overlay div.modal_inner{
    width: 400px;
    padding:60px 30px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
}
.modal_overlay div .close_modal_button {
    position: absolute;
    right:20px;
    padding: 0;
    top:20px;
    width: 20px;
    cursor: pointer;
    height: 20px;
}
.modal_overlay div .close_modal_button img{
    width: 15px;
    height: 15px;
}
.touch .mail{
    text-align: left;
}
.touch .mail ul li{
    margin: 20px 0;
}
#Privacy .banner_uppr_txt h2, #terms .banner_uppr_txt h2{
    color:#000;
    font-size: 24px;
}

@media only screen and (max-width: 1199px) {
    footer.footer h3 {
        font-size: 33px;

    }
}
@media only screen and (max-width: 1024px) {
    #banner_sec {
        padding: 100px 0 100px;
        height: auto;
    }
    #Budget_Friendly{
        padding: 100px 0 100px;
        height: auto;
    }
    #boutique{
        padding: 100px 0 100px;
        height: auto;
    }
    #Casino_Hotels{
        padding: 100px 0 100px;
        height: auto;
    }
    #banner{
        padding: 100px 0 100px;
        height: auto;
    }
    #Contact{
        padding: 100px 0 100px;
        height: auto;
    }
    #terms{
        padding: 74px 0 0px;
        height: auto;
    }
    #Privacy{
        padding: 74px 0px;
        height: auto;
    }
}
@media only screen and (max-width: 991px) {
    .sidenavs .closebtn {
        display: block;
        line-height: 25px;
        right: 7px;
        margin: 0;
    }
    footer.footer{
        text-align: center;
    }
    .header_navigation {
        display: flex;
        justify-content: end;

    }
    ul.menu_navigation {
        display: inline-block;
        width: 100%;
    }
    ul.menu_navigation li, ul.top-menu li {
        padding: 0;
        border-bottom: 1px solid #ddddddc7;
    }
    .sidenavs a {
        margin: 0;
    }
    ul.menu-btns li {
        padding: 10px 8px;
        width: 100%;
    }
    li.become-member .btn{
        margin-right: 0;
    }
    span.toggle_menu {
        display: inline-block;
        color: #deb666;
        font-size: 30px;
        position: relative;
        right: 0;
        top: 0px;
        float: left;
        margin-left: 15px;
    }
    .sidenavs {
        width: 0;
        position: fixed;
        /* background: #333059; */
        top: 0;
        left: 0;
        display: inline-block;
        overflow-x: hidden;
        background: linear-gradient(13deg, #3EB489   0%, #0A2540 50%, rgb(0 0 0) 100%);
        height: 100vh;
        transition: all 0.3s;
    }
    ul.menu_navigation {
        padding-top: 50px;
    }
    .sidenavs a , ul.top-menu a{
        font-size: 16px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        color: #fff;
    }
    .sidenavs .menu-btns a{
        display: block;
        text-align: center;
    }
    .mid_menu_header {
        padding: 20px 0 20px 0;
    }
    .top-brs.for-mob, .menu-btns {
        display:block;
    }
    .top-brs.for-desk{
        display:none;
    }
    .top-brs .top-menu {
        display:block;
        border-bottom: 0;
        padding: 40px 0 0;
    }
    li.lst-btn {
        padding: 10px!important;
        text-align: center;
    }
    li.lst-btn a {
        text-align: center;
        justify-content: center;
    }
    footer.footer h3 {
        font-size: 25px;
    }
    .main-addrs p img {
        width: 24px;
    }
    .main-addrs p {
        font-size: 14px;
        padding-left: 35px;
    }
    .rqst-btn a {
        padding: 11px 24px;
        font-size: 18px;
    }
    .ftr-links li {
        margin-right: 14px;
    }
    .ftr-links li a,p.cpy-rt {
        font-size: 14px;
    }
    .scrl-icons a {
        width: 40px;
        height: 40px;
        font-size: 16px;
        margin-left: 10px;
    }
    .main_hotel img {
        height: 280px;
    }
    .banner_uppr_txt h1 {
        font-size: 30px;
    }
    .banner_uppr_txt p {
        font-size: 16px;
    }
    .Useful_link {
        margin: 50px 0;
    }
    .policey ul {
        display: flex;
        flex-direction: column;
    }
    .policey ul li a {
        padding: 10px 0;

    }
    section.cardone h2 {
        font-size: 30px;

    }
    .text_hotel{
        margin-bottom: 30px;
    }
    .form_div {
        display: flex;
        flex-direction: column;
    }
    .touch {
        margin-top: 40px;
    }
    .contact_us_f h2 {
        font-size: 26px;
    }
    .touch h4 {
        font-size: 26px;
    }
}
@media only screen and (max-width: 767px) {
    footer.footer h3 {
        text-align: center;
    }
    .rqst-btn,.scrl-icons{
        text-align: center;
        margin-top: 40px;
    }
    .ftr-links ul {
        text-align: center;
        margin-bottom: 15px;
    }
    p.cpy-rt {
        text-align: center;
    }


}
@media only screen and (max-width: 575px) {
    .main-addrs {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    h1.beste.space {
        font-size: 34px;
        padding-top: 60px;
        border-bottom: 3px solid #deb666;
    }
    h1.beste{
        font-size: 34px !important;
        border-bottom: 3px solid #deb666;
    }
    .main-addrs {
        margin-top: 35px;
    }
    .main-addrs p img {
        width: 24px;
        position: static;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }.main-addrs p {
         font-size: 14px;
         padding-left: 0;
         margin-bottom: 20px;
     }
    .addrs {
        width: 100%;
    }
}
@media only screen and (max-width: 359px) {

}
@media(max-width: 900px){
    #mySidenavs{
        z-index: 99999;
    }
    .banner_uppr_txt h2{
        font-size: 20px;
        margin-top: 10px;
    }
}